import {reactive} from "vue";
import distributionModel from "@/api/addons/distribution";
import tool from "@/util/tool";


export function useWithdraw(){
    let wState = reactive({
        list:[],
        page:1,
        count:0,
        limit:10,
        search:{name:'',account:'',status:1},
        statusForm:{
            show:false,
            id:0,
            msg:'',
        }
    })

    function getWithdrawList(page,limit){
        distributionModel.getDisWithdraw(page,limit,wState.search,res=> {
            wState.list = res.list
            wState.page = res.page
            wState.count = res.count
            wState.limit = limit
        })
    }
    function agreeWithdraw(id,status){
        distributionModel.changeWithdrawStatus(id,status,"",()=>{
            getWithdrawList(wState.page,wState.limit)
        })
    }
    function showReject(id){
        wState.statusForm.id = id
        wState.statusForm.show = true
    }

    function rejectWithdraw(){
        let { id,msg } = wState.statusForm
        if( !tool.returnMessage(msg,'请输入驳回理由')) return
        distributionModel.changeWithdrawStatus(id,3,msg,()=>{
            wState.statusForm.show = false
            wState.statusForm.msg = ""
            getWithdrawList(wState.page,wState.limit)
        })
    }

    return { wState,getWithdrawList,agreeWithdraw ,showReject,rejectWithdraw}
}
